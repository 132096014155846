/** paleta de colores **/
:root {
  --reuse-primary: #221fce;
  --reuse-morado: #7051a0;
  --reuse-azul: #0473c8;
  --reuse-celeste: #ccdaec;
  --reuse-bg: #f8f8f8;
  --reuse-verde: #09aa53;
  --reuse-pink: #f7f7ff;
  --reuse-tbl-th: #d5c9e7;
  --reuse-color-title: #9d9dae;
  --reuse-bg-title: #e9e8eb;
}

$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px,
xxxl: 1540px
);

$container-max-widths: (
sm: 540px,
md: 720px,
lg: 960px,
xl: 1140px,
xxl: 1320px,
xxxl: 1480px
);

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap");
@import "~bootstrap/scss/bootstrap.scss";

/* framework */
.bg-azul {background-color: var(--reuse-azul);}
.bg-azul{background-color: var(--reuse-azul);}
.bg-morado{background-color: var(--reuse-morado) !important;}
.mh-100{min-height: 100%;}
.fw-black{font-weight: 900;}

/* general */
body {background-color: var(--reuse-pink);margin: 0;font-family:  Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
#root {height: 100vh;min-height: 600px;}

/* Layout */
.content {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6.375rem;
}
.navbar-glass {
  background-color: var(--reuse-pink);
}
.navbar-glass-shadow {
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0,0,0,.2) !important;
}

.navbar-top {
  position: sticky;
  top: 0;
  font-size: .8333333333rem;
  font-weight: 600;
  margin-left: -1rem;
  margin-right: -1rem;
  z-index: 1020;
  background-image: none;
  min-height: 4.3125rem;
}


/*menu  */
.navbar-toggle-icon {
  position: relative;
  height: 0.125rem;
  width: 1.25rem;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  display: block;
  top: -0.3125rem;
  padding-right: 0;
}
.navbar-toggler-humburger-icon {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.3125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar-toggle-icon:after, .navbar-toggle-icon:before {
  content: "";
  position: absolute;
  height: 0.125rem;
  background-color: var(--reuse-primary);
  left: 0;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  border-radius: 0.25rem;
  width: 100%;
}
.navbar-toggle-icon:before {
  top: 0.3125rem;
}
.navbar-toggle-icon:after {
  top: 0.625rem;
}
.navbar-vertical .toggle-icon-wrapper {
  margin-left: -0.75rem;
  padding-left: 0.125rem;
  margin-right: 1.25rem;
}
.navbar-toggle-icon .toggle-line {
  display: block;
  height: 100%;
  border-radius: 0.25rem;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  background-color: var(--reuse-primary);
}
.navbar-brand {
  color: var(--reuse-azul);
  white-space: nowrap;
  cursor: pointer;
}
.navbar-vertical {
  position: fixed;
  display: inline-block;
  padding: 0;
  //z-index: 1019;
  top: 4.3125rem;
  width: 100%;
  max-width: 100vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 -1rem;
  background-image: none;
}
.navbar-vertical.navbar-expand-xl {
  max-width: 12.625rem;
  top: 0;
  height: 100vh;
  margin: 0;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-vertical .navbar-collapse {
  overflow: hidden;
}
.navbar-vertical .navbar-collapse {
  background-color: var(--reuse-primary);
}

.buttonExcel{
  text-align: right
}
.buttonExcel button{
  font-size: 11px;
  margin-bottom: 20px;
}

.chip-status{
  height:29px !important;
  border-radius: 4px !important;
}
.chip-status span{
  padding: 0px 8px;
}

@media (min-width: 1200px){
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon:before {
    width: 50%;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon:after {
  width: 75%;
}
  .navbar-vertical.navbar-expand-xl .navbar-vertical-toggle {
    display: flex;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-toggle-icon {
  padding-right: 0.3125rem;
}
  .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 15.625rem;
    }

    .navbar-vertical.navbar-vertical-collapsed {
      z-index: 1030;
      width: 3.125rem;
      height: 100vh;
  }
  .navbar-vertical.navbar-expand-xl+.content .navbar-top {
    position: sticky;
    top: 0;
  }
  .navbar-vertical-collapsed.navbar-expand-xl+.content .navbar-top {
    padding-left: 13.5rem;
  }
  .navbar-vertical-collapsed.navbar-expand-xl+.content {
    margin-left: 0;
}

.navbar-expand-xl .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    margin-top: -0.3125rem;
    transition: width .2s ease;
    transition-property: width,box-shadow;
    display: inline-block !important;
    width: 12.625rem;
  }
  .navbar-vertical-collapsed.navbar-expand-xl .navbar-collapse {
    width: 3.125rem !important;
    margin-left: -1rem;
    overflow: hidden;
}
}
[role="presentation"]{
  overflow: auto
}
.table-desktop{
  display:block;
}
.table-mobile{
  display:none;
}
@media only screen and (max-width: 990px) {
  .shopify-button a{
    margin-right: 0;
    margin-top: 20px;
    display:block;
    text-align: center
  }
  .table-desktop{
    display:none;
  }
  .table-mobile{
    display:block;
  }
}
.MuiTab-root.Mui-selected{
  font-weight: bold
}